import React from "react"
import { PageWrapper } from "~components/Core"
import HeroSection from '~sections/Common/Hero'
import ServicesSection from '~sections/Common/Services'
import AboutSection from '~sections/Services/About'
import ContentSectionOne from '~sections/Services/ContentOne'
import ProcessSection from '~sections/Services/Process'
import BookingSection from '~sections/Services/Booking'
import AdvantagesSection from '~sections/Services/Advantages'
import FooterFour from '~sections/Common/Footer'
import data from "~data-yaml/services.yml"
import HeroBg from "~image/heroimages/services/hero_bg.jpg"
import heroOverlay from "~image/heroimages/services/overlay.png"

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
}

export default function Services() {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection
          title={data.hero.title}
          subtitle={data.hero.subtitle}
          text={data.hero.text}
          HeroBg={HeroBg}
          Overlay={heroOverlay}
        />
        <ServicesSection/>
        <AboutSection/>
        <ContentSectionOne/>
        <ProcessSection/>
        <BookingSection/>
        <AdvantagesSection/>
        <FooterFour/> 
        </PageWrapper>
  )
}
