import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ServicesCard from "./Component/Card"
import Service from "./style"
import { useStaticQuery, graphql } from "gatsby"
import Serv from "~data-yaml/services.yml"

const ServicesSection = () => {
  const data = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {type: {eq: "serviceDetails"}}},sort: {fields: [frontmatter___title], order: ASC}) {
      nodes {
        id
        excerpt
        slug
        frontmatter {
          icon
          title
          iconBackground
        }
      }
    }
  }
`)
  return (
    <Service backgroundColor="rgba(169, 210, 255, 0.1)">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xxl-6 col-xl-7 col-md-9 col-sm-10">
            <Service.Box className="text-center" mb="30px" mbLG="55px">
              <Service.Subtitle as="h6" fontColor="#ff1e38">
                {Serv.services.subtitle}
              </Service.Subtitle>
              <Service.Title as="h2">{Serv.services.title}</Service.Title>
            </Service.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
            {data.allMdx.nodes.map((node,i) => {
              return (
                <Col
                  xs="12"
                  className="col-xl-4 col-lg-6 col-md-6 col-sm-9 col-xs-10 "
                  key={node.id}
                >
                <ServicesCard
                  title={node.frontmatter.title}
                  text={node.excerpt}
                  icon={node.frontmatter.icon}
                  to={"/" + node.slug}
                  iconBackground={node.frontmatter.iconBackground}
                />
              </Col>
              )
            })}
        </Row>
      </Container>
    </Service>
  )
}


export default ServicesSection
