import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import { Col, Container, Row } from 'react-bootstrap'
import CounterBlock from './Components/CounterBlock'
import SectionTitle from '~sections/Common/SectionTitle'
import About from './style'
import Data from "~data-yaml/services.yml"

const AboutSection = ({ ...rest }) => {
  const iName_1 = Data.about.image_1
  const iName_2 = Data.about.image_2
  const data = useStaticQuery(graphql`
  query servAboutImage($image: String ! = "/home-services/") {
      allFile(filter: {relativePath: {regex: $image}}) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      dataYamlYaml(page: {eq: "services"}) {
        about {
          text
        }
      }
    }
`)

  const iData = (name) => {
    let iDat = undefined
    for (let i = 0; i < data.allFile.edges.length; i++) {
      if (data.allFile.edges[i].node.base == name) {
        iDat = data.allFile.edges[i].node.childImageSharp.gatsbyImageData
      }
    }
    return iDat
  }

  return (
    <About  {...rest}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs="12" className="col-xl-5 col-lg-5 col-md-7 col-xs-8">
            <About.Image mb="30px" mbLG="0">
              <Img image={iData(iName_1)} alt="about" layout="fullWidth" placeholder="blurred" />
              <About.ImageInner>
                <Img image={iData(iName_2)} alt="about" layout="fullWidth" placeholder="blurred" />
              </About.ImageInner>
            </About.Image>
          </Col>
          <Col xs="12" className="col-xl-5 offset-xl-2 col-lg-6 offset-lg-1 col-md-9 col-sm-11">
            <About.Box>
              <About.ContentTextBlock className="text-center text-lg-start">
              <SectionTitle 
                subTitle={Data.about.subtitle}
                title={Data.about.title}
                text={data.dataYamlYaml.about.text}
                titleProps={{mb:"30px"}}
                subTitleProps={{mb:"25px"}}
                />
              </About.ContentTextBlock>
            </About.Box>
          </Col>

        </Row>
      </Container>
    </About>
  )
}
export default AboutSection