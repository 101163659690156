import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Col, Container, Row } from 'react-bootstrap'
import { GatsbyImage as Img } from "gatsby-plugin-image"
import ContentWidget from './Components/Widget'
import Content from './style'
import SectionTitle from '~sections/Common/SectionTitle'
import Data from "~data-yaml/services.yml"

const ContentSectionOne = ({ ...rest }) => {
  const iName = Data.content.image
  const data = useStaticQuery(graphql`
  query servContentImage($image: String ! = "/home-services/") {
      allFile(filter: {relativePath: {regex: $image}}) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      dataYamlYaml(page: {eq: "services"}) {
        about {
          text
        }
      }
    }
`)

  const iData = (name) => {
    let iDat = undefined
    for (let i = 0; i < data.allFile.edges.length; i++) {
      if (data.allFile.edges[i].node.base == name) {
        iDat = data.allFile.edges[i].node.childImageSharp.gatsbyImageData
      }
    }
    return iDat
  }

  return (
    <Content backgroundColor="rgba(169, 210, 255, 0.1)" {...rest}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs="12" className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-xs-11 order-2 order-lg-1">
            <SectionTitle
              subTitle={Data.content.subtitle}
              title={Data.content.title}
              text={Data.content.text}
              titleProps={{ mb: "30px" }}
              subTitleProps={{ mb: "25px" }}
            />
            <Content.Widget>
              {Data.content.items.map(({ title, text,icon }, i) => {
                return (
                  <ContentWidget
                    icon={icon}
                    title={title}
                    text={text}
                    key={i}
                  />
                )
              })}
            </Content.Widget>
          </Col>
          <Col xs="12" className="col-xxl-7 col-xl-6 col-lg-5 col-md-9 col-xs-10 order-1 order-lg-2">
            <Content.Widget mb="30px" mbXL="0px">
              <Img image={iData(iName)} alt="content" layout="fullWidth" placeholder="blurred" />
            </Content.Widget>
          </Col>
        </Row>
      </Container>
    </Content>

  )
}

export default ContentSectionOne
