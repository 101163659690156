import React, { useRef } from "react"
import Testimonial from './style'
import { Container, Row, Col } from 'react-bootstrap'
import Serv from "~data-yaml/services.yml"
import styled from 'styled-components';

const AdvGrid = styled.div`
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`
const ServCard = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: #fff; 
  .fa {
    padding-right: 16px;
  }
`

const AdvantagesSection = () => {
  return (
    <Testimonial backgroundColor="#f3f4f6">
      <Container>
        <Row>
          <Col xs="12" className="col-lg-8 col-xl-9 col-xxl-7">
            <Testimonial.Box mbLG="55px" mbSM="30px">
              <Testimonial.Subtitle fontColor="#ff1e38" as="h6">{Serv.advSubtitle}</Testimonial.Subtitle>
              <Testimonial.Title mb="20px" as="h2">{Serv.advTitle}</Testimonial.Title>
            </Testimonial.Box>
          </Col>
        </Row>
        <AdvGrid>
          {Serv.advItems.map((adv, i) => {
            return (
              <ServCard key={"adv" + i}>
                <p>
                  <i className="fa fa-check"></i>
                  {adv}
                </p>
              </ServCard>
            )
          })}
        </AdvGrid>
      </Container>
    </Testimonial>
  )
}
export default AdvantagesSection