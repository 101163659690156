import React from 'react'
import { Link } from "~components"
import ProcessCard from './Components/Card'
import Process from './style'
import Data from "~data-yaml/services.yml"
import { Container, Row, Col } from 'react-bootstrap'

const ProcessSection = ({ ...rest }) => {
  return (
    <Process backgroundColor="#6001d3" {...rest}>
      <Container>
        <Row className="align-items-center justify-content-center justify-content-xl-start order-2 order-xl-1">
          <Col xs="12" className="col-xl-4 col-md-10 text-center text-xl-start">
            <Process.Box mt="20px" mtXL="0px">
              <Process.Subtitle as="h5">{Data.process.subtitle}</Process.Subtitle>
              <Process.Title as="h2">{Data.process.title}</Process.Title>
              <Process.Text as="p">{Data.process.text}</Process.Text>
              <Process.Button className="btn-torch-red" to={Data.process.cta_link} as={Link}>{Data.process.cta_label}</Process.Button>
            </Process.Box>
          </Col>
          <Col xs="12" className="col-xl-8 order-1 order-xl-2">
            <Process.Box as="div" className="widgets">
              <Row className="justify-content-center">
                {Data.process.items.map(({ title, text }, i) => {
                  return (
                    <Col xs="12" className="col-lg-4 col-md-4 col-xs-6" key={"id"+i}>
                      <ProcessCard 
                      icon={i+1} 
                      title={title} 
                      text={text} />
                    </Col>
                  )
                })}
              </Row>
            </Process.Box>
          </Col>
        </Row>
      </Container>
    </Process>

  )
}
export default ProcessSection